/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import Components from '../Loader'
import Layout from '../components/Layout'

const theme = {
  "colors": {
    "front1": "rgba(20,20,20,1)",
    "front2": "rgba(255,255,255,1)",
    "front3": "rgba(17,17,17,1)",
    "front4": "rgba(178,178,178,1)",
    "back1": "rgba(255,255,255,1)",
    "back2": "rgba(0,0,0,1)",
    "back3": "rgba(236,236,236,1)",
    "back4": "rgba(178,178,178,1)"
  },
  "variables": {
    "color-variable-1": "rgba(8,73,152,1)",
    "color-variable-2": "rgba(0,0,0,1)",
    "color-variable-btn-1": "rgba(255, 255, 255, 1)",
    "color-variable-btn-2": "rgba(0, 0, 0, 1)"
  },
  "globalMobile": "27em",
  "meta": []
}

export default function Page(props) {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Components.Column name={"uvod"} style={{"backgroundColor":"var(--color-variable-1)"}} layout={"l1"} fullscreen={false}>
          
          <Components.ColumnWrap className="column__flex --center el--1" columns={"1"} fullscreen={false}>
          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="--style2 bg--left --full pb--30 pt--30" name={"f081gsuk7m5"} styleFlag={2} fullscreen={true} css={css`
          @media (min-width: 1401px) {
            background-image: url(https://cdn.swbpg.com/t/2058/691af61fefb94708b63eae7c633e945c_ove=000000x15_s=2000x_.jpg);
          }
          @media (max-width: 1400px) {
            background-image: url(https://cdn.swbpg.com/t/2058/691af61fefb94708b63eae7c633e945c_ove=000000x15_s=1400x_.jpg);
          }
        `}>
          
          <Components.ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1889,"marginTop":0,"paddingTop":0}} columns={"1"}>
            
            <Components.ColumnWrapper >
              
              <Components.Title className="title-box fs--102 title-box--invert lh--1" style={{"maxWidth":2018}} content={"OKAMŽITÝ PRODEJ NEMOVITOSTI<br><br>VAŠE RYCHLÉ A POHODLNÉ ŘEŠENÍ<br>"}>
              </Components.Title>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="--l2R1 pb--40 pt--40" name={"uvod2"} style={{"paddingTop":null,"paddingBottom":null}}>
          
          <Components.ColumnWrap className="column__flex --center el--4 --full flex--top" columns={"4"} fullscreen={true}>
            
            <Components.ColumnWrapper >
              
              <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-5.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":80}} srcSet={""} content={null}>
              </Components.Image>

              <Components.Subtitle className="subtitle-box swpf--uppercase" content={"až 95% tržní ceny"} uppercase={"true"}>
              </Components.Subtitle>

              <Components.Text className="text-box fs--24" content={"Za Vaši nemovitost nabídneme maximální možnou cenu."}>
              </Components.Text>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper >
              
              <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5d46cc970eb64c6e8a034b7a1871de24.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":80}} srcSet={""} content={null}>
              </Components.Image>

              <Components.Subtitle className="subtitle-box swpf--uppercase" style={{"maxWidth":312}} content={"dům/byt/půda/chata"} uppercase={"true"}>
              </Components.Subtitle>

              <Components.Text className="text-box fs--24" content={"Jsme schopni odkoupit jakýkoliv typ nemovitosti, včetně komerčních nemovitostí."}>
              </Components.Text>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper >
              
              <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/447466f0ca0249c3bd3d8ba8c5b74267.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":80}} srcSet={""} content={null}>
              </Components.Image>

              <Components.Subtitle className="subtitle-box swpf--uppercase" content={"100% DISKRÉTNOST"} uppercase={"true"}>
              </Components.Subtitle>

              <Components.Text className="text-box fs--24" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Components.Text>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper >
              
              <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Oteviraci doba/7ad8faafc07c4b46b2d17e42cdd9458d.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":80}} srcSet={""} content={null}>
              </Components.Image>

              <Components.Subtitle className="subtitle-box swpf--uppercase" content={"RYCHLOST"} uppercase={"true"}>
              </Components.Subtitle>

              <Components.Text className="text-box fs--24" content={"Celý prodej zorganizujeme v řádu dní, peníze můžete mít i do 24 hodin."}>
              </Components.Text>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="pb--30 pt--30" name={"sluzby"} style={{"backgroundColor":"rgba(246, 248, 249, 1)"}} layout={"l5"}>
          
          <Components.ColumnWrap className="el--1" columns={"1"}>
            
            <Components.ColumnWrapper className="--center pb--12 pt--12" style={{"paddingTop":null,"paddingBottom":null}}>
              
              <Components.Subtitle className="subtitle-box swpf--uppercase" content={"<span style=\"color: rgb(8, 8, 8);\">NĚCO O NÁS</span><br>"} uppercase={"true"}>
              </Components.Subtitle>

              <Components.Text className="text-box fs--20" style={{"maxWidth":1008}} content={"<span style=\"color: var(--color-variable-1);\">Každý se může dostat do problémů a potřebuje rychle peníze. Nabízíme Vám zdarma ocenění Vaší nemovitosti a možnost prodat v podstatě okamžitě. Můžete se tak ihned těšit na peníze z prodeje.&nbsp;<br><br>Jsme přímým kupcem, jednáme férově, transparentně a rychle.<br><br><br>Rádi Vám navíc poradíme jak jednat s exekutory, věřiteli a pomůžeme Vám vyřešit Vaší případnou složitou situace.&nbsp;<br><br><br>Nabízíme také možnost následného pronájmu nemovitosti přímo Vám za výhodných podmínke - Váš životní standard tedy nijak neutrpí a Vaše okolí se o prodeji nedozví.&nbsp;<br></span>"}>
              </Components.Text>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

          <Components.ColumnWrap className="el--3" columns={"3"}>
          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="pb--30 pt--30" name={"sluzby"} style={{"backgroundColor":"rgba(246, 248, 249, 1)"}} layout={"l5"}>
          
          <Components.ColumnWrap className="el--1" columns={"1"}>
            
            <Components.ColumnWrapper className="--center pb--12 pt--12" style={{"paddingTop":null,"paddingBottom":null}}>
              
              <Components.Subtitle className="subtitle-box swpf--uppercase" content={"<span style=\"color: rgb(8, 8, 8);\">VÝHODY RYCHLÉHO ODKUPU</span>"} uppercase={"true"}>
              </Components.Subtitle>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

          <Components.ColumnWrap className="el--3" style={{"maxWidth":1268}} columns={"3"}>
            
            <Components.ColumnWrapper className="--center pb--12 pt--12" style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Components.ColumnHeaderWrap className="flex --left">
                
                <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/g/Ikonky/Finance/finanfce-1.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} content={null}>
                </Components.Image>

                <Components.ColumnContent >
                  
                  <Components.Subtitle className="subtitle-box fs--20 swpf--uppercase" style={{"maxWidth":245}} content={"100% VYŘEŠENÍ prodeje"} uppercase={"true"}>
                  </Components.Subtitle>

                  <Components.Text className="text-box mt--02" content={"Veškerou administrativu vyřídíme za Vás.&nbsp;"}>
                  </Components.Text>

                </Components.ColumnContent>

              </Components.ColumnHeaderWrap>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center pb--12 pt--12" style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Components.ColumnHeaderWrap className="flex --left">
                
                <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-25.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} content={null}>
                </Components.Image>

                <Components.ColumnContent >
                  
                  <Components.Subtitle className="subtitle-box fs--20 swpf--uppercase" style={{"maxWidth":""}} content={"ZPĚTNÝ PRONÁJEM"} uppercase={"true"}>
                  </Components.Subtitle>

                  <Components.Text className="text-box mt--02" content={"Nemovitost Vám můžeme pronajmout.&nbsp;"}>
                  </Components.Text>

                </Components.ColumnContent>

              </Components.ColumnHeaderWrap>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center pb--12 pt--12" style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Components.ColumnHeaderWrap className="flex --left">
                
                <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-6.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} content={null}>
                </Components.Image>

                <Components.ColumnContent >
                  
                  <Components.Subtitle className="subtitle-box fs--20 swpf--uppercase" style={{"maxWidth":245}} content={"VYŘEŠÍME DLUHY"} uppercase={"true"}>
                  </Components.Subtitle>

                  <Components.Text className="text-box mt--02" content={"Poradíme jak jednat s věřiteli, exekutory. Případně povedeme jednání za Vás.&nbsp;"}>
                  </Components.Text>

                </Components.ColumnContent>

              </Components.ColumnHeaderWrap>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center pb--12 pt--12" style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Components.ColumnHeaderWrap className="flex --left">
                
                <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-17.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} content={null}>
                </Components.Image>

                <Components.ColumnContent >
                  
                  <Components.Subtitle className="subtitle-box fs--20 swpf--uppercase" style={{"maxWidth":245}} content={"KOMERČNÍ NEMOVITOSTI"} uppercase={"true"}>
                  </Components.Subtitle>

                  <Components.Text className="text-box mt--02" content={"Vykupujeme také komerční nemovitosti.&nbsp;"}>
                  </Components.Text>

                </Components.ColumnContent>

              </Components.ColumnHeaderWrap>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center pb--12 pt--12" style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Components.ColumnHeaderWrap className="flex --left">
                
                <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/g/Ikonky/Finance/finanfce-5.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} content={null}>
                </Components.Image>

                <Components.ColumnContent >
                  
                  <Components.Subtitle className="subtitle-box fs--20 swpf--uppercase" style={{"maxWidth":245}} content={"JAKÝKOLIV STAV"} uppercase={"true"}>
                  </Components.Subtitle>

                  <Components.Text className="text-box mt--02" content={"Nemovitost vykoupíme v jakémkoliv stavu.&nbsp;"}>
                  </Components.Text>

                </Components.ColumnContent>

              </Components.ColumnHeaderWrap>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center pb--12 pt--12" style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Components.ColumnHeaderWrap className="flex --left">
                
                <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Adresa/adresa-1.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} content={null}>
                </Components.Image>

                <Components.ColumnContent >
                  
                  <Components.Subtitle className="subtitle-box fs--20 swpf--uppercase" style={{"maxWidth":245}} content={"MORAVSKOSLEZSKÝ KRAJ"} uppercase={"true"}>
                  </Components.Subtitle>

                  <Components.Text className="text-box mt--02" content={"Výkup realizujeme v rámci MS kraje.&nbsp;"}>
                  </Components.Text>

                </Components.ColumnContent>

              </Components.ColumnHeaderWrap>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="pb--30 pt--30" name={"o-mne"} layout={"l4"}>
          
          <Components.ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <Components.ColumnWrapper >
              
              <Components.Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/26/fullscreen_s=350x_.jpg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/i/template/26/fullscreen_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/26/fullscreen_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/26/fullscreen_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/26/fullscreen_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/26/fullscreen_s=2000x_.jpg 2000w"}>
              </Components.Image>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--left" style={{"maxWidth":572}}>
              
              <Components.Text className="text-box fs--22 w--900" style={{"maxWidth":418}} content={"Jak bude probíhat naše spolupráce"}>
              </Components.Text>

              <Components.Subtitle className="subtitle-box swpf--uppercase" content={"• KONTAKTUJTE NÁS"} uppercase={"true"}>
              </Components.Subtitle>

              <Components.Subtitle className="subtitle-box swpf--uppercase" content={"• ANALYZUJEME SITUACI"} uppercase={"true"}>
              </Components.Subtitle>

              <Components.Subtitle className="subtitle-box swpf--uppercase" content={"• OCENÍME NEMOVITOST<br><br><br>• VYPLATÍME PENÍZE<br><br>"} uppercase={"true"}>
              </Components.Subtitle>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="pb--30 pt--30" name={"kontakt"}>
          
          <Components.ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <Components.ColumnWrapper >
              
              <Components.Subtitle className="subtitle-box swpf--uppercase" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">CHCI RYCHLE PRODAT SVOU NEMOVITOST</span>"} uppercase={"true"}>
              </Components.Subtitle>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper >
              
              <Components.ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Jméno","type":"text","required":true,"placeholder":"Zadejte Vaše jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","required":true,"placeholder":"Telefon +420"},{"name":"Typ nemovitosti","type":"textarea","required":true,"placeholder":"dům/byt/rekreační nemovitost/pozemek/komerční nemovitost"},{"name":"Bližší popis a lokalita","type":"textarea","placeholder":"Napište zde prosím bližší popis a v jaké obci se nemovitost nachází"},{"name":"Odeslat","type":"submit","align":"center"}]} layout={"l2"} styleFlag={1}>
              </Components.ContactForm>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="pb--30 pt--30" name={"kontaktujte-me"} layout={"l1"} css={css`
          @media (min-width: 1401px) {
            background-image: url(https://cdn.swbpg.com/t/i/template/26/img-1_ove=000000x60_s=2000x_.jpg);
          }
          @media (max-width: 1400px) {
            background-image: url(https://cdn.swbpg.com/t/i/template/26/img-1_ove=000000x60_s=1400x_.jpg);
          }
        `}>
          
          <Components.ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <Components.ColumnWrapper >
              
              <Components.Title className="title-box fs--43 title-box--invert swpf--uppercase" content={"ZA VAŠI NEMOVITOST MAXIMUM"} uppercase={"true"}>
              </Components.Title>

              <Components.Text className="text-box fs--24 text-box--invert" style={{"maxWidth":420}} content={"Kontaktovat&nbsp;&nbsp;<span style=\"font-weight: bold;\">+420 771 113 189</span>"}>
              </Components.Text>

              <Components.Button className="btn-box btn-box--hvr2 btn-box--shape3 swpf--uppercase" href={"#uvod"} content={"Zpět nahoru"} uppercase={"true"}>
              </Components.Button>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>

      </Layout>
    </ThemeProvider>
  )
}